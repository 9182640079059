import { ElMessage } from 'element-plus';
import { convertDate } from '../../services/helpers';
export const msg = {
  state: () => ({
    msgs: [],
    msg: {
      list: [],
    },
    msgCount: 0,
  }),
  getters: {
    msgs({ msgs }) {
      return msgs;
    },
    msgCount({ msgCount }) {
      return msgCount;
    },
    msgTitle({ msgTitle }) {
      return msgTitle;
    },
  },
  mutations: {
    msg(state, payload) {
      state.msg = payload;
      state.msg.date = convertDate(state.msg.date);
    },
  },

  actions: {
    getSmsStudent({dispatch}, payload) {
      return dispatch(`getAxios`, {
        url: `msg/student/${payload}`
      } );
    },

    async sendSMSs({ dispatch }, payload) {
      let res = await dispatch('postAxios', {
        url: `msg/send`,
        data: payload,
      });
      if (res.status == 200) {
        ElMessage({
          message: `SMS ketdi`,
          type: 'success',
        });
      }
    },
  },
};
