import { ElMessage } from 'element-plus'

export const part    = {
    state: () => ({
        parts: [],
        partTitle:'Qabul'
    }),
    getters: {
        parts({parts}){
            return parts
        }
    },

    // mutations
    mutations:{
        addParts(state,payload){
            state.parts = [payload,...state.parts.map(part => {
                return {...part,status:1}
            })]
        },
        updatePart(state,payload){
            state.parts = state.parts.map(part => {
                if (payload._id == part._id) return payload
                return part
            })
        },
        statusPart(state,payload){
            state.parts = state.parts.map(part => {
                if (part._id == payload._id) return payload
                part.status = 1
                return part
            })
        },
        parts(state,payload){
            state.parts = [...payload]
        }
    },


    actions:{
        async getParts({dispatch, commit},payload){
            let res = await dispatch('getAxios',{url:'part',search:payload})
            if (res.status === 200){
                commit('parts',res.data)
            }
        },
        async getPart({dispatch},payload){
            return await dispatch('getAxios',{url:`part/get/${payload}`})
        },
        async addPart({dispatch,commit,state},payload){
            let res = await dispatch('postAxios',{url:'part',data:payload})
            if (res.status === 201){
                commit('addParts',res.data)
                ElMessage({
                    message: `Yangi ${state.partTitle.toLowerCase()} qo'shildi`,
                        type: 'success',
                    })
            }
        },
        async statusPart({dispatch,commit,state},payload){
            let res = await dispatch('getAxios',{url:`part/status/${payload}`})
            if (res.status == 200){
                commit('statusPart',res.data)
                ElMessage({
                    message: `${state.partTitle} holati o'zgartirildi`,
                        type: 'success',
                    })
            }
        },
        async savePart({dispatch,commit,state},payload){
            if (payload){
                let res = await dispatch('putAxios',{url:'part',data:payload})
                if (res.status === 200){
                    commit('updatePart',res.data)
                    ElMessage({
                        message: `${state.partTitle} holati o'zgartirildi`,
                        type: 'success',
                    })
                }
            }
        },
        async changePart({dispatch},payload){

            await dispatch('getAxios',{url:`part/changestatus/${payload._id}/${payload.status}`})

        }
        
    }
}