import { createStore } from 'vuex'

import { layoutApp } from './modules/layout'
import { auth } from './modules/auth'
import { notification } from './modules/notif'
import { modal } from './modules/modal'

import { direction } from './data/direction'
import { students } from './data/student'
import { worker } from './data/worker'
import { report } from './data/report'
import { helpers } from './helpers'
import { part } from './data/part'
import { kvitansiya } from './data/kvitansiya'
import { exam } from './data/exam'
// import { crud } from './data/crud'
import { template } from './data/template'
import { page } from './data/page'
import { role } from './data/role'

import { subject } from './data/subject'
import { test } from './data/test'
import { testpack } from './data/testpack'
import { testproccess } from './data/testproccess'
import { msg } from './data/msg'
import { theme } from './data/theme'

export default createStore({
  state: {
    url: 'https://qabulapi.auniver.uz',
    loading:false,
    month: [
      'Yanvar',
      'Fevral',
      'Mart',
      'Aprel',
      'May',
      'Iyun',
      'Iyul',
      'Avgust',
      'Sentabr',
      'Oktabr',
      'Noyabr',
      'Dekabr',
    ],
  },
  getters: {
    month({ month }) {
      return month;
    },
    url({ url }) {
      return url;
    },
    loading({loading}){
      return loading
    }
  },
  mutations: {
    setLoading(state,payload){
      state.loading = payload
    }
  },
  actions:{
    loading({commit},payload){
      commit('setLoading',payload)
    }
  },
  modules: {
    part,
    helpers,
    layoutApp,
    auth,
    notification,
    modal,
    direction,
    students,
    worker,
    report,
    kvitansiya,
    exam,
    msg,
    template,
    // crud,
    page,
    role,

    testproccess,
    test,
    testpack,
    subject,
    theme
  },
});
