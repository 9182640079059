import axios from "axios"
import { ElMessage } from 'element-plus'

export const helpers = {
    actions: {
        getAxios(context,payload){
            return axios.get(`${context.getters.url}/${payload.url}`,{headers:context.getters.header,params:payload.search})
            .catch(e => {
                
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            })
        },
        postAxios(context,payload){
            return axios.post(`${context.getters.url}/${payload.url}`,payload.data,{headers:context.getters.header}).catch(e => {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            })
        },
        deleteAxios(context,payload){
            return axios.delete(`${context.getters.url}/${payload}`,{headers:context.getters.header}).catch(e => {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            })
        },
        putAxios(context,payload){
            return axios.put(`${context.getters.url}/${payload.url}`,payload.data,{headers:context.getters.header}).catch(e => {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            })
        },
    }
}

export const addZero = (val) => {
    return val < 10 ? '0' + val : val
}

export const dynamicSort = (property) => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}



let device;
let endpointNumber = 1

const getDevices = async () => await navigator.usb.getDevices().then(([printer]) => Promise.resolve(printer));

const sendMessageToPrinter = (content) => {
  const encoder = new TextEncoder();

  const data = encoder.encode(content);
//   console.log(content);

//   console.log('trying ', endpointNumber);

  return device.transferOut(endpointNumber, data);
};

export const initPrinter = async () => {
  await navigator.usb.requestDevice({
    filters: [],
  });
  device = await getDevices();

  
  await device.open();
  await device.selectConfiguration(1);

//   console.log(device.configuration.interfaces[0].interfaceNumber);
  
  await device.claimInterface(device.configuration.interfaces[0].interfaceNumber);
  

  endpointNumber = 1;
};

export const startPrint = async (content) =>
    sendMessageToPrinter(content)
    .catch((e) => {
        if (e.message.includes('The specified endpoint is not part of a claimed and selected alternate interface')) {
            if (endpointNumber < 15) {
                endpointNumber = endpointNumber + 1;
                startPrint(content);
                return;
            }

            return;
        }

    })
    .then((e) => console.log(e));


    export const convertFullDate = (date) => {
        let d = new Date(date);
        return `${addZero(d.getDate())}/${addZero(
        d.getMonth() + 1
        )}/${d.getFullYear()} ${addZero(d.getHours())}:${addZero(
        d.getMinutes()
        )}`;
    };