<template>
  <el-config-provider :locale="locale">
    <component :is="layout"/>
    <notif />
  </el-config-provider>
</template>
 
<script>
import NotificationBox from '@/components/NotificationBox.vue';
import AdminLayout from '@/layout/adminLayout.vue';
import UserLayout from '@/layout/userLayout.vue';
import { ElConfigProvider } from 'element-plus'
import uz from 'element-plus/dist/locale/uz-uz.mjs'

import AuthLayout from '@/layout/authLayout.vue';
export default {
  data: () => ({
    locale: uz
  }),
  components: {
    ElConfigProvider,
    notif: NotificationBox,
    'admin-layout': AdminLayout,
    'user-layout': UserLayout,
    'auth-layout': AuthLayout,
  },
  computed:{
    layout(){
      return this.$store.getters.layout
    }
  },
  methods:{
    checkUser(){
      if (this.$cookies.isKey('user')){
        this.$store.dispatch('checkUser',this.$cookies.get('user'))
      } else {
        this.$router.push('/')
        this.$store.dispatch('setLayout','auth-layout')
      }
      
    }
  },
  watch:{
    // $route(){
    //   this.checkUser()
    // }
  },
  mounted(){
    document.title = 'Qabul | Angren Universiteti'
    this.checkUser()

  }
}
</script>

<style lang="scss">
@import './styles/reset.scss';
@import './styles/grid.scss';
@import './styles/main.scss';
@import './styles/lib/class.scss';
</style>
