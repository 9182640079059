import axios from "axios"

import { dynamicSort } from "../helpers"

export const direction    = {
    state: () => ({
        direction: [],
        onedirection:{},
        activDirection:[],
        statistika: [],
        magstatistika: [],
        gender: [],
        maggender: [],
        studyTypes:['Kunduzgi','Sirtqi','Kechki'],
        todaycount:0
    }),
    getters: {
        todaycount(state){
            return state.todaycount
        },
        studyTypes(state){
            return state.studyTypes
        },
        reportDirection(state){
            return state.direction
        },
        magDirection(state){
            return state.direction.filter(dir => dir.lvl == 1)
        },
        bakDirection(state){
            return state.direction.filter(dir => dir.lvl == 0)
        },
        direction(state){
            return state.direction
        },
        typeCount(state){
            let count = [0, 0, 0];
            state.statistika.forEach(stat => {
                count[0] += stat.on
                count[1] += stat.off
                count[2] += stat.night
            })
            return count
        },
        typeTitle(state){
            let count = [0, 0, 0];
            state.statistika.forEach(stat => {
                count[0] += stat.on || 0
                count[1] += stat.off || 0
                count[2] += stat.night || 0
            })
            count[0] = `Kunduzgi (${count[0]})`
            count[1] = `Sirtqi (${count[1]})`
            count[2] = `Kechki (${count[2]})`
            return count
        },
        magtypeCount(state){
            let count = [0, 0, 0];
            state.magstatistika.forEach(stat => {
                count[0] += stat.on
                count[1] += stat.off
                count[2] += stat.night;
            })
            return count
        },
        magtypeTitle(state){
            let count = [0,0,0]
            state.magstatistika.forEach(stat => {
                count[0] += stat.on || 0
                count[1] += stat.off || 0
                count[2] += stat.night || 0
            })
            count[0] = `Kunduzgi (${count[0]})`
            count[1] = `Sirtqi (${count[1]})`
            count[2] = `Kechki (${count[2]})`;
            return count
        },
        onedirection(state){
            return state.onedirection
        },
        getActive(state){
            return state.activDirection
        },
        // for statistic home page
        statistika(state){
            return state.statistika
        },
        magstatistika(state){
            return state.magstatistika
        },
        gender(state){
            return state.gender
        },
        
        magtitles(state){
            return state.magstatistika.map(item => {
                item = `${item.title} (${item.off + item.on})`
                return item
            })
        },
        magcount(state){
            return state.magstatistika.map(item => {
                item = item.off + item.on
                return item
            })
        },
        titles(state){
            let list = state.statistika.map((item) => {
              item = `${item.title} (${item.off + item.on + item.night})`;
              return item;
            });
            return list
        },
        count(state){
            return state.statistika.map(item => {
                item = item.off + item.on + item.night
                return item
            })
        },

        gentitle(state) {
            return state.gender.map(item => {
                item = `${item.titles} (${item.count})`
                return item
            })
        },
        gencount(state) {
            return state.gender.map(item => {
                item = item.count
                return item
            })
        },
        maggencount(state) {
            return state.maggender.map(item => {
                item = item.count
                return item
            })
        },
        maggentitle(state) {
            return state.maggender.map(item => {
                item = `${item.titles} (${item.count})`
                return item
            })
        },
    },

    // mutations
    mutations:{
        actDirection(state,payload){
            state.activDirection = payload
        },
        direction(state,payload){
            state.direction = payload
        },
        addDirection(state,payload){
            state.direction.unshift(payload)
        },
        deleteDirection(state,payload){
            let index = state.direction.findIndex(h => h._id == payload)
            if (index !==-1){
                state.direction.splice(index,1)
            }
        },
        upDirection(state,payload){
            let index = state.direction.findIndex(h => h._id == payload._id)
            if (index !==-1){
                state.direction[index].title = payload.title
            }
        },
         // for statistic home page
        statistika(state,payload){
            state.statistika = payload.sort(dynamicSort('title'))
        },
        magstatistika(state,payload){
            state.magstatistika = payload.sort(dynamicSort('title'));
        },
        gender(state,payload){
            state.gender = payload
        },
        maggender(state,payload){
            state.maggender = payload
        },
        todaycount(state,payload){
            state.todaycount = payload
        }
    },


    actions:{
        activeDirection(context){
            axios.get(`${context.getters.url}/direction/all`,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('actDirection',res.data)
                }
            })
        },

        getDirection(context,payload){
            return axios.get(`${context.getters.url}/direction/edit/${payload}`,{
                headers: context.getters.header
            })
        },
        deleteDirection(context,payload){
            axios.delete(`${context.getters.url}/direction/delete/${payload}`,{
                headers: context.getters.header
            })
            .then(res => {
                if (res.status == 200){
                    context.commit('notification',{
                        type:'success',
                        message:'Yo`nalish o`chirildi'
                    })
                    context.commit('deleteDirection',payload)
                }
            })
        },
        allDirection(context){
            axios.get(`${context.getters.url}/direction`,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    // console.log(res.data)
                    context.commit('direction',res.data)
                }
            })
        },

        addDirection(context,payload){
            axios.post(`${context.getters.url}/direction/create`, payload, {
                headers: context.getters.header
            })
            .then(res=> {
                
                if (res.status==201){
                    context.commit('notification',{
                        type:'success',
                        message:'Yangi yo`nalish qo`shildi'
                    })
                    context.commit('addDirection', res.data)
                }
            })
        },
        saveDirection(context,payload){
            axios.post(`${context.getters.url}/direction/save/${payload._id}`,payload,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('notification',{
                        type:'success',
                        message:'Ma`lumot yangilandi'
                    })
                    context.dispatch('allDirection')
                }
            })
        },
        // for  satatistic home page
        statistic(context){
            axios.get(`${context.getters.url}/statistic`,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('statistika',res.data.bak.sort((a,b)=>  a.value - b.value))
                    context.commit(
                      'magstatistika',
                      res.data.mag.sort((a, b) => a.value - b.value)
                    );
                }
            })
        },
        getTodayStudents(context){
            axios.get(`${context.getters.url}/statistic/today`,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('todaycount',res.data.students)
                }
            })
        },
        gender(context){
            axios.get(`${context.getters.url}/statistic/genderstatistic`,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('gender',res.data.bak)
                    context.commit('maggender',res.data.mag)
                }
            })
        },
        async getDirections({dispatch},payload){
            return await dispatch('getAxios',{
                url: `direction/bylvl/${payload}`
            })
        }
    }
}