<template>
  <div class="wrapper__head">
    <h2>Angren Universiteti Qabul axborot tizimi</h2>
    <div>
      <div class="header__search">
        <el-input v-model="look" @input="findMe()" placeholder="Qidiruv" class="mr-1" clearable />
        <ul class="header__result">
          <li 
            v-for="student of findresult" 
            v-show="findresult.length > 0" 
            :key="student._id"
            @click="toUrl(`/student/${student._id}`)" 
            :class="`status-${student.status}`">
              <div class="header__name">
                {{ student.name }} {{ student.lname }} {{ student.secondname }}</div>
              <div class="header__phone">{{ student.phone }}</div>
          </li>
        </ul>
        <el-button 
          class="mr-1"
          v-if="user.login == 'admin'"
          @click="$router.push('/settings')"
          >
          <el-icon ><setting/></el-icon>
        </el-button>  
        <el-dropdown>
          <span class="el-dropdown-link head_name">
            {{ user.name }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="$router.push('/profile')">
                <el-icon class="mr-1"><edit/></el-icon>
                Profil
              </el-dropdown-item>             
                         
              <el-dropdown-item divided @click="exit">
                <el-icon 
                  class="mr-1">
                  <switch-button/>
                </el-icon> 
                Chiqish
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        
        <!-- <el-button type="warning" class="header__exit" @click="exit">
            <el-icon class="mr-1"><switch-button></switch-button></el-icon> Chiqish
        </el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    look:''
  }),
  computed: {
    ...mapGetters([
      'user'
    ]),
    findresult() {
      return this.$store.getters.findresult;
    }
  },
  methods: {
    findMe() {
      if (this.look) {
        this.$store.dispatch("findresult", this.look);
      }
      else {
        this.$store.commit("findresult", []);
      }

    },
    toUrl(url) {
      this.$router.push(url);
      this.look = "";
      this.$store.commit("findresult", []);
    },
    exit() {
      if (confirm("Вы хотите выйти?")) {
        this.$store.dispatch("exit");
        this.$router.push("/");
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/part/header.scss';
</style>