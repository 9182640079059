import { ElMessage } from 'element-plus';

export const role = {
  state: () => ({
    roles: [],
    roleTitle: 'Foydalanuvchi turi',
    roleRules: {
      title: [{ required: true, message: 'Foydalanuvchi turi nomini kiriting' }],
      slug: [{ required: true, message: 'Foydalanuvchi turi qisqa matnini kiriting' }],
    },
  }),
  getters: {
    roles({ roles }) {
      roles =  roles.map(role =>{
        role.access = role.access.map(item => {
          item.content = ''
          item.cruds.forEach(crud => {
            item.content += crud + '<br/>'
          })
          
          return item
        })
        return role
      })
      return roles;
    },
    roleRules({ roleRules }) {
      return roleRules;
    },
  },
  mutations: {
    addRoles(state, payload) {
      state.roles = [payload, ...state.roles];
    },
    updateRole(state, payload) {
      state.roles = state.roles.map((role) => {
        if (payload._id == role._id) return payload;
        return role;
      });
    },
    statusRole(state, payload) {
      state.roles = state.roles.map((role) => {
        if (role._id == payload._id) return payload;
        return role;
      });
    },
    roles(state, payload) {
      state.roles = [...payload];
    },
  },

  actions: {
    async getRoles({ dispatch, commit }, payload) {
      let res = await dispatch('getAxios', { url: 'role', search: payload });
      if (res.status === 200) {
        // console.log(res.data)
        commit('roles', res.data);
      }
    },
    async getRole({ dispatch }, payload) {
      return await dispatch('getAxios', { url: `role/get/${payload}` });
    },
    async addRole({ dispatch, commit, state }, payload) {
      let res = await dispatch('postAxios', { url: 'role', data: payload });
      if (res.status === 201) {
        commit('addRoles', res.data);
        ElMessage({
          message: `Yangi ${state.roleTitle.toLowerCase()} qo'shildi`,
          type: 'success',
        })
        dispatch('getRoles');
      }
    },
    async statusRole({ dispatch, commit, state }, payload) {
      let res = await dispatch('getAxios', { url: `role/status/${payload}` });
      if (res.status == 200) {
        commit('statusRole', res.data);
        ElMessage({
          message: `${state.roleTitle} holati o'zgartirildi`,
          type: 'success',
        })
        
      }
    },

    async saveRole({ dispatch, commit, state }, payload) {
      if (payload) {
        let res = await dispatch('putAxios', { url: 'role', data: payload });
        if (res.status === 200) {
          commit('updateRole', res.data);
          ElMessage({
            message: `${state.roleTitle} holati o'zgartirildi`,
            type: 'success',
          })
          dispatch('getRoles')
        }
      }
    },

    async removeRole({dispatch}, payload){
      let res = await dispatch('deleteAxios',`role/${payload}`)
      if (res.status == 200){
        dispatch('getRoles')
      }
    }

  },
};
