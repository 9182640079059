import axios from "axios"
import cookie from 'vue-cookies'
import router from "@/router"
import { ElMessage } from "element-plus";

export const auth = {
  state: () => ({
    user: {},
    token: '',
    header: {},
    role: '',
    retro: '',
    menu: [],
  }),
  getters: {
    menu({ menu }) {
      return menu;
    },
    retro({ retro }) {
      return retro;
    },
    user({ user }) {
      return user;
    },
    token({ token }) {
      return token;
    },
    header({ header }) {
      return header;
    },
    role({ role }) {
      return role;
    },
  },
  mutations: {
    retro(state, payload) {
      state.retro = payload;
    },
    menu(state, payload) {
      state.menu = payload;
    },
    role(state, payload) {
      state.role = payload;
    },
    user(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    header(state, payload) {
      state.header = payload;
    },
  },
  actions: {
    retro(context) {
      axios
        .get(`${context.getters.url}/users/getpass`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('retro', res.data);
          }
        });
    },


    login(context, payload) {
      axios
        .post(`${context.getters.url}/auth/login`, payload)
        .then((res) => {
          if (res.status == 200) {
            let { token, user } = res.data;
            if (res.data.user.role == 'user') {
              context.commit('menu', user.access.access);
            }
            context.commit('user', user);
            context.commit('setToken', token);
            context.commit('layout', user.role + '-layout');
            context.commit('role', user.role);
            context.commit('header', {
              authorization: `Bearer ${token}`,
            });
            delete user.role;
            cookie.set('user', {
              user: {
                id: user.id,
                login: user.login,
                name: user.name,
              },
              token,
            });

            router.push('/bak');
          }
        })
        .catch((err) => {
          let { response } = err;
          context.dispatch('notification', {
            type: 'error',
            message: response.data.message,
          });
        });
    },
    async checkUser({ commit, dispatch }, payload) {
      commit('user', payload.user);
      // console.log(payload)
      commit('setToken', payload.token);
      commit('header', {
        authorization: `Bearer ${payload.token}`,
      });
      let res = await dispatch('postAxios', {
        url: 'auth/checkuser',
        data: payload.user,
      });

      if (res.status == 200) {
        if (res.data.role == 'user') {
          commit('menu', res.data.access.access);
        }
        commit('role', res.data.role);
        commit('layout', `${res.data.role}-layout`);
      } else commit('layout', 'auth');
    },
    exit(context) {
      cookie.remove('user');
      context.commit('layout', 'auth-layout');
      context.commit('user', {});
      context.commit('setToken', '');
    },
    async checkLogin({ dispatch }, payload) {
      // console.log(payload)
      // return axios.post(`${context.getters.url}/auth/checklogin`,payload,{headers:context.getters.header})
      return await dispatch('postAxios', {
        url: 'auth/checklogin',
        data: payload,
      });
    },
    async checkPhone({ dispatch }, payload) {
      // console.log(payload);
      return await dispatch('postAxios', {
        url: 'auth/checkphone',
        data: payload,
      });
    },
    async checkPhoneProfile({ dispatch }, payload) {
      // console.log(payload);
      return await dispatch('postAxios', {
        url: 'auth/checkphoneprofile',
        data: payload,
      });
    },

    getProfile(context) {
      return context.dispatch('getAxios', { url: 'auth/my' });
    },
    saveProfile({ dispatch, commit }, payload) {
      dispatch(`putAxios`, { url: 'auth/save', data: payload }).then((res) => {
        if (res.status == 200) {
          commit('user', res.data);
          ElMessage({
            message: 'Ma`lumotlar yangilandi',
            type: 'success',
          });
          router.push('/');
        }
      });
    },
  },
};